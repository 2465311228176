let AircraftMassAndBalance = (function () {

    let _private = {};

    _private.instance = this;

    _private.from = $();

    /**
     *
     * @param {*} form
     */
    this.init = function (form) {
        form          = $(form);
        _private.form = form;
        form.data('AircraftMassAndBalance', this);
        _private.bindEvents();
    };

    /**
     *
     */
    _private.bindEvents = function () {
        _private.form.find('.cog-list')
                .unbind('click.toggleMbLineActivation')
                .on('click.toggleMbLineActivation',
                    'input[type=checkbox][name*=installed],input[type=checkbox][name*=weighted]',
                    function () {
                        _private.calculateEquipmentTotal();
                    }
                );
        _private.form.find('.cog-list')
                .unbind('keyup.calculateEquipmentTotal')
                .on('keyup.calculateEquipmentTotal', 'input[name*=arm],input[name*=weight]',
                    function (event) {
                        let currentElement = $(event.target);
                        if (currentElement.attr('name') === 'equipment_weight') {
                            return;
                        }
                        _private.calculateEquipmentTotal();
                    }
                );
        _private.form.find('.cog-list')
                .unbind('formTableElementRemove.calculateEquipmentTotal')
                .on('formTableElementRemove.calculateEquipmentTotal',
                    function () {
                        _private.calculateEquipmentTotal();
                    }
                );
    };


    /**
     *
     * @param {*} checkbox
     */
    _private.toggleMbLineActivation = function (checkbox) {
        let line   = $(checkbox).parents('div.form-table-line');
        let select = $(line).find('select');
        let text   = $(line).find('input[type=text]');
        if ($(checkbox).is(':checked')) {
            $(select).removeAttr('disabled').change();
            $(text).removeAttr('readonly').keyup();
        } else {
            $(select).attr('disabled', 'disabled');
            $(text).attr('readonly', 'readonly');
        }
        $(line).find('input[name*=moment]').attr('readonly', 'readonly');
    };

    /**
     *
     */
    _private.calculateEquipmentTotal = function () {
        let equipWeight = 0;
        let lines       = _private.form.find('fieldset.equipment-list,fieldset.seat-list').find('div.form-table-line');
        lines.each(
            function (i, line) {
                line = $(line);
                if (line.hasClass('inactive')) {
                    return;
                }
                let lWeight   = line.find('input[type=text][name*=weight]').uVal();
                let installed = line.find('input[type=checkbox][name*=installed]').is(':checked');
                let weighted  = line.find('input[type=checkbox][name*=weighted]').is(':checked');
                if (installed && !weighted) {
                    equipWeight += lWeight;
                } else if (!installed && weighted) {
                    equipWeight -= lWeight;
                }
            }
        );
        _private.form.find('input[name=equipment_weight]').uVal(equipWeight).keyup();
    };

});